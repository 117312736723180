
#root{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container{
    width: 97%;
    display: flex;
    flex-direction: column;
    padding: 20px 0 55px 0 ;
}