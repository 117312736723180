.form{
    display: flex;
    flex-direction: column;
}

.form__label{
    display: flex;
    flex-direction: column;

    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #29344C;
    margin-bottom: 5px;


}

.form__header{
    margin-bottom: 10px;
    margin-left: 10px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 15px;
    display: flex;
    align-items: center;

    color: #29344C;
}

.form__input{
    border: 1px solid #ECEFF3;
    box-shadow: inset 0px -1px 1px rgba(0, 0, 0, 0.25), inset 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    background: #fff;
}